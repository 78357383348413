import React from 'react';
import Layout from 'components/layout';
import Construction from 'images/anim0205-1_e0.gif';
import Head from 'components/head';
import Helmet from 'react-helmet';

const Portfolio = () => (
  <Layout>
    <Head pageTitle="Portfolio" />
    <Helmet
      meta={[
        {
          name: 'description',
          content:
            'This portfolio is still under development. Please check back soon!',
        },
        {
          name: 'keywords',
          content:
            'ui / ux, ui, ux, design, writing, code, opinions, pop, culture, technology, stuff',
        },
      ]}
    />
    <div style={{ textAlign: 'center', margin: '3rem auto', color: '#fff' }}>
      <h1>Im sorry to say but this is still under development.</h1>
      <img src={Construction} alt="" />
    </div>
  </Layout>
);

export default Portfolio;
